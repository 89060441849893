import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IBranch } from "@/interfaces/IBranch";
import { ulrStringify } from "@/utils/UrlUtils";

const URL = "branches";

@Component
export default class BranchRequestMixin extends Vue {
  public branches: Array<IBranch> = [];
  public branchesLoading = false;
  public branchesSuccess = false;
  public branchesError: Record<any, any> | null = null;

  public async findAllBranch(params: any = {}): Promise<void> {
    this.branchesLoading = true;
    this.branchesSuccess = false;
    this.branchesError = null;

    const args = ulrStringify(params);

    return await axios
      .get(`${URL}${args}`)
      .then((response: AxiosResponse) => {
        this.branches = response.data;
        this.branchesSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.branchesError = error.response?.data;
      })
      .finally(() => {
        this.branchesLoading = false;
      });
  }
}





























































import { Component, Prop } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { mixins } from "vue-class-component";
import StudentMixin from "@/mixins/StudentMixin";
import FscCard from "@/components/Card/FscCard.vue";
import FscCardHeader from "@/components/Card/FscCardHeader.vue";
import MailButton from "@/components/Button/MailButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import PriceMixin from "@/mixins/PriceMixin";
import _ from "lodash";

@Component({
  components: {
    PrintButton,
    MailButton,
    FscCardHeader,
    FscCard,
    SaveButton,
    AbortButton,
  },
})
export default class AddOnsPriceListTab extends mixins(StudentMixin, PriceMixin) {
  public name = "AddOnsPriceListTab";

  @Prop({ default: () => [] })
  public addons!: any;

  @Prop({ default: () => [] })
  public priceLists!: any;

  @Prop({ default: () => [] })
  public bookedAddons!: any;

  public addonsFields = [
    { label: this.$t("price_list.addon"), key: "product.name" },
    {
      label: this.$tc("general.license_class", 1),
      key: "licenseClass",
    },
    { label: this.$t("students.price"), key: "priceInEur" },
    {
      label: this.$t("students.price_combined"),
      key: "priceInEurForAdditionalClass",
    },
    { label: this.$t("general.book_goods"), key: "booked" },
  ];

  public priceListFields = [
    { label: this.$t("roles.description"), key: "product.name" },
    { label: this.$t("calendar.class"), key: "licenseClass" },
    { label: this.$t("general.art"), key: "product" },
    { label: this.$t("product.prices"), key: "priceInEur" },
    { label: "", key: "vat" },
  ];

  public addOnsMailHandle(): void {
    console.log("add on mail");
  }

  public addOnsPrintHandle(): void {
    console.log("add on print");
  }

  public priceListMailHandle(): void {
    console.log("price list mail");
  }

  public priceListPrintHandle(): void {
    console.log("price list print");
  }

  protected get addonOption(): Array<any> {
    return this.addons;
  }

  protected get priceListOption(): Array<any> {
    return this.priceLists;
  }

  protected bookedInput(val: any, data: any): void {
    if (data.booked) {
      return;
    }
    const bookedObject = {
      studentEducationId: data.studentEducation.id,
      productId: data.product.id,
      licenseClass: data.licenseClass,
    };

    this.updateSelectionBooked(bookedObject);
  }

  protected updateSelectionBooked(resource: any): any {
    if (!_.find(this.bookedAddons, resource)) return this.bookedAddons.push(resource);
    const index = _.findIndex(this.bookedAddons, resource);
    if (index > -1) return this.bookedAddons.splice(index, 1);
  }

  protected reformatPercent(value: number): string {
    return `${value}%`;
  }

  protected reformatProductShortName(value: any): string {
    return value.standardProductShortName ? value.standardProductShortName : "&#8212;";
  }
}

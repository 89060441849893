import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { ulrStringify } from "@/utils/UrlUtils";
import { ITheoryCourse } from "@/interfaces/ITheoryCourse";

const URL = "theory-courses";

@Component
export default class CourseRequestMixin extends Vue {
  public theoryCourses: Array<ITheoryCourse> = [];
  public theoryCoursesLoading = false;
  public theoryCoursesSuccess = false;
  public theoryCoursesError: Record<any, any> | null = null;

  public async findAllTheoryCourse(params: any = {}): Promise<void> {
    this.theoryCoursesLoading = true;
    this.theoryCoursesSuccess = false;
    this.theoryCoursesError = null;

    const args = ulrStringify(params);

    return await axios
      .get(`${URL}${args}`)
      .then((response: AxiosResponse) => {
        this.theoryCourses = response.data;
        this.theoryCoursesSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.theoryCoursesError = error.response?.data;
      })
      .finally(() => {
        this.theoryCoursesLoading = false;
      });
  }
}

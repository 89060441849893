









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RegistrationInfoButton extends Vue {
  public name = "RegistrationInfoButton";

  @Prop({ default: () => "Speichern", type: String })
  public label!: string;

  @Prop({ default: () => false, type: Boolean })
  public disabled!: boolean;
}

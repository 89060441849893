import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IBranch } from "@/interfaces/IBranch";

const URL = "testing-organizations";

@Component
export default class TestingOrganizationRequestMixin extends Vue {
  public testingOrganizations: Array<IBranch> = [];
  public testingOrganizationsLoading = false;
  public testingOrganizationsSuccess = false;
  public testingOrganizationsError: Record<any, any> | null = null;

  public async filterTestingOrganizations(filter: any = {}): Promise<void> {
    this.testingOrganizationsLoading = true;
    this.testingOrganizationsSuccess = false;
    this.testingOrganizationsError = null;

    return await axios
      .post(`${URL}/filter`, filter)
      .then((response: AxiosResponse) => {
        this.testingOrganizations = response.data;
        this.testingOrganizationsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.testingOrganizationsError = error.response?.data;
      })
      .finally(() => {
        this.testingOrganizationsLoading = false;
      });
  }
}

import { Component, Vue } from "vue-property-decorator";
import axios from "@/utils/axios";
import { AxiosError, AxiosResponse } from "axios";
const URL = "/license-classes";

@Component
export default class KeyNumberRequestMixin extends Vue {
  public keysByLicenseClass: any = [];
  public keysByLicenseClassLoading = false;
  public keysByLicenseClassError = null;
  public keysByLicenseClassSuccess = false;

  public async findKeysByLicenseClass(license: any): Promise<void> {
    this.keysByLicenseClassLoading = true;
    this.keysByLicenseClassSuccess = false;
    if (license == undefined) return Promise.reject(false);
    return await axios
      .get(`${URL}/key-numbers?baseLicenseClass=${license}`)
      .then((response: AxiosResponse) => {
        this.keysByLicenseClass = response.data;
        this.keysByLicenseClassSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.keysByLicenseClassError = error.response?.data;
      })
      .finally(() => {
        this.keysByLicenseClassLoading = false;
      });
  }
}

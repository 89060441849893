






















































import { Vue, Component, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Avatar from "@/components/Avatars/Avatar.vue";

@Component({
  components: {
    FscSimpleCard,
    Avatar,
  },
})
export default class RegistrationInfoModal extends Vue {
  public name = "RegistrationInfoModal";

  @Prop()
  public registration!: any;

  public calculateYears(birthYear: any) {
    let newAge = Date.now() - birthYear.getTime();
    let currentAge = new Date(newAge);
    return Math.abs(currentAge.getUTCFullYear() - 1970);
  }

  public get birthDate(): string {
    return this.registration
      ? `${this.$options.filters?.formatDate(this.registration.birthDate)} (${this.calculateYears(new Date(this.registration.birthDate || 0))})`
      : "";
  }

  public get licenseClass(): string {
    return this.registration.licenseClass ? this.registration.licenseClass.name : "";
  }

  public get theoryCourse(): string {
    return this.registration.theoryCourse ? this.registration.theoryCourse.name : "";
  }

  public get paymentWorkflow(): string {
    return this.registration.paymentWorkflow ? this.registration.paymentWorkflow.name : "";
  }

  public get branchName(): string {
    return this.registration.branch ? `${this.registration.branch.postalCode} - ${this.registration.branch.location}` : "";
  }
}
